exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.wrap_69PY8VoY {\r\n  position: fixed;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  background: rgba(0, 0, 0, .4);\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseMask.vue"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,QAAQ;EACR,SAAS;EACT,8BAA8B;CAC/B","file":"BaseMask.vue","sourcesContent":["\n.wrap {\r\n  position: fixed;\r\n  top: 0;\r\n  bottom: 0;\r\n  left: 0;\r\n  right: 0;\r\n  background: rgba(0, 0, 0, .4);\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrap": "wrap_69PY8VoY"
};