exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.avatar_2SmYPAaS {\r\n  width: 30px;\r\n  height: 30px;\r\n  border-radius: 50%;\r\n  line-height: 30px;\r\n  text-align: center;\r\n  color: #fff;\r\n  background-color: #00a8e5;\r\n  cursor: pointer;\n}\r\n\r\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseAvatar.vue"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,0BAA0B;EAC1B,gBAAgB;CACjB","file":"BaseAvatar.vue","sourcesContent":["\n.avatar {\r\n  width: 30px;\r\n  height: 30px;\r\n  border-radius: 50%;\r\n  line-height: 30px;\r\n  text-align: center;\r\n  color: #fff;\r\n  background-color: #00a8e5;\r\n  cursor: pointer;\n}\r\n\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"avatar": "avatar_2SmYPAaS"
};