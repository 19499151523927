exports = module.exports = require("../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.wrap_2d8lQMpw {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  margin: 0 auto;\n}\n", "", {"version":3,"sources":["D:/blog/client/src/common/BaseFullScreen.vue"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,OAAO;EACP,UAAU;EACV,QAAQ;EACR,SAAS;EACT,+BAA+B;UACvB,uBAAuB;EAC/B,eAAe;CAChB","file":"BaseFullScreen.vue","sourcesContent":["\n.wrap {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"wrap": "wrap_2d8lQMpw"
};