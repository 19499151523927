/* script */
var __vue_script__ = null
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../node_modules/_vue-loader@14.2.3@vue-loader/lib/template-compiler/index?{\"id\":\"data-v-5cb97356\",\"hasScoped\":false,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../../node_modules/_vue-loader@14.2.3@vue-loader/lib/selector?type=template&index=0!./SVGHome.vue"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = null
/* scopeId */
var __vue_scopeId__ = null
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../node_modules/_vue-loader@14.2.3@vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)

export default Component.exports
