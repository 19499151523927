exports = module.exports = require("../../../node_modules/_css-loader@0.28.11@css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n.title_2uhRexqi {\r\n  float: left;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: calc(100% - 32px);\r\n  padding: 4px 0;\r\n  font-size: 1.2em;\r\n  cursor: default;\n}\n.exit_xlvJONYE {\r\n  float: right;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: 32px;\r\n  padding: 2px 6px;\r\n  font-size: 26px;\r\n  cursor: pointer;\n}\r\n", "", {"version":3,"sources":["D:/blog/client/src/components/Alert/AlertWithText.vue"],"names":[],"mappings":";AACA;EACE,YAAY;EACZ,+BAA+B;UACvB,uBAAuB;EAC/B,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;CACjB;AACD;EACE,aAAa;EACb,+BAA+B;UACvB,uBAAuB;EAC/B,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;CACjB","file":"AlertWithText.vue","sourcesContent":["\n.title {\r\n  float: left;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: calc(100% - 32px);\r\n  padding: 4px 0;\r\n  font-size: 1.2em;\r\n  cursor: default;\n}\n.exit {\r\n  float: right;\r\n  -webkit-box-sizing: border-box;\r\n          box-sizing: border-box;\r\n  width: 32px;\r\n  padding: 2px 6px;\r\n  font-size: 26px;\r\n  cursor: pointer;\n}\r\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "title_2uhRexqi",
	"exit": "exit_xlvJONYE"
};